import React from 'react'

function About() {
    return (
        <div className="about-area mt-95">
            <div className="container">
                <div className="row">
                    <div className="col-lg-7">
                        <div className="about-container">
                            <h3>ABOUT <span className="orange-color">EDUCATION </span> <span className="orange-color">HUB</span> FENI</h3>
                            <p>Education Hub Feni provides free advice and support to International students applying to study abroad  including  UK and USA .</p>
                            <p>Our education consultants are trained and experienced for many years. We are here to help guide you every steps for application process to UK University admission from Foundation to Masters Level . we are closely working with our partners in the UK</p>
                                  
                        </div>
                    </div>
                    <div className="col-lg-5">
                        <div className="about-image-area img-full">
                            <img src="img/about/about1.jpg" alt="" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default About
