import React from 'react'

    function DirectorMessage() {
        return (
            <>
            <h1 class="text-center pt-20">Director Message</h1><br></br>
            <div class="blog-area pb-100">
                <div class="container">
                    <div class="row">
                        <div class="col-lg-8 pt-100 blog-post-item offset-lg-2">
                            <div class="blog-wrapper blog-details">
                                <div class="blog-content">
                                    <h3>Dear Students, Parents & Partners,</h3>
                                    <p>Education Hub Feni is established with a mission to provide quality study abroad solutions to the students who wants to achieve reputed degree from recognised world ranking University in globally.   I take this opportunity to thank you for considering us to be part of your educational endeavours. Our team can guide you application process, university selection, course selection, answer any queries about cost of living in the UK, accommodation and teaching facilities as well.</p>
                                    <p>We want to make the overall journey of the students from home country to abroad without any obstacle including information about University, Tuition fees, accommodation etc.</p>
                                    <p>I am delighted to inform to student that we are not charging any fee for consultation for selection university, programme and accommodation as well. we are totally free of all the services to student.</p>
                                    <p>I once again thank you for believing in us and giving us the opportunity to serve you.</p>
                                    <br></br>
                                    <p><strong>Yours Sincerely,<br></br>
                                    Razya Akter<br></br>
                                    Managing Director , Education Hub Feni</strong></p>
                                </div>
                            </div>
                            
                        </div>
                    </div>
                </div>
            </div>
            </>
        )
    }


export default DirectorMessage