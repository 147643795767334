import React from 'react';
import { Link } from 'react-router-dom';

function Contact() {
    return (
        <>
        <div className="breadcrumb-banner-area">
            <div className="container">
                <div className="row">
                    <div className="col-md-12">
                        <div className="breadcrumb-text">
                            <h1 className="text-center">Contact</h1>
                            <div className="breadcrumb-bar">
                                <ul className="breadcrumb text-center">
                                    <li><Link to="/">Home</Link></li>
                                    <li>Contact</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        
        <div className="contact-area pt-100 pb-100">
            <div className="container">
                <div className="row g-0">
                    <div className="col-md-12 col-lg-6 blue-bg">
                        <div className="edubuzz-address">
                            <h2 className="contact-title">You Can Contact With Us</h2>
                            <p>EDUCATION HUB FENI</p>
                            <ul>
                                <li><i className="fa fa-fax"></i> Address : FENI SADAR, FENI</li>
                                <li><i className="fa fa-phone"></i> Phone : +88</li>
                                <li><i className="fa fa-phone"></i> Email : @</li>
                                <li><i className="fa fa-envelope-o"></i> Web: www</li>
                            </ul>
                            <div className="contact-social">
                                <h3><strong>Also Can Find Us</strong></h3>
                                <ul>
                                    <li><Link to="#"><i className="zmdi zmdi-facebook"></i></Link></li>
                                    <li><Link to="#"><i className="zmdi zmdi-twitter"></i></Link></li>
                                    <li><Link to="#"><i className="zmdi zmdi-google-plus"></i></Link></li>
                                    <li><Link to="#"><i className="zmdi zmdi-instagram"></i></Link></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-12 col-lg-6 yellow-bg">
                        <div className="contact-form-wrap">
                            <h2 className="contact-title">SEND YOUR MESSAGE</h2>
                            <form id="contact-form" action="mail.php" method="post">
                                <div className="row">
                                    <div className="col-12">
                                        <div className="contact-form-style mb-20">
                                            <input name="name" placeholder="Name*" type="text" />
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="contact-form-style mb-20">
                                            <input name="phone" placeholder="Phone*" type="text" />
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="contact-form-style mb-20">
                                            <input name="email" placeholder="Email*" type="email" />
                                        </div>
                                    </div>
                                    <div className="col-md-12">
                                        <div className="contact-form-style">
                                            <textarea name="message" placeholder="Type your message here.."></textarea>
                                            <button className="button-default" type="submit"><span>Send Email</span></button>
                                        </div>
                                    </div>
                                </div>
                            </form>
                            <p className="form-messege"></p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        </>
    )
}

export default Contact
