import React from 'react'

function Footer() {
    return (
        <footer>
            {/* Newsletter Area Start */}
            <div className="newsletter-area">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-5 col-md-5">
                            <div className="newsletter-content">
                                <h3>SUBSCRIBE</h3>
                                <h2>TO OUR NEWSLETTER</h2>
                            </div>
                        </div>
                        <div className="col-lg-7 col-md-7">
                            <div className="newsletter-form angle">
                                <form action="http://devitems.us11.list-manage.com/subscribe/post?u=6bbb9b6f5827bd842d9640c82&amp;id=05d85f18ef" method="post" id="mc-embedded-subscribe-form" name="mc-embedded-subscribe-form" className="mc-form footer-newsletter fix">
                                    <div className="subscribe-form">
                                        <input id="mc-email" type="email" autoComplete="off" placeholder="Enter your email here" />
                                        <button id="mc-submit" type="submit">SUBSCRIBE</button>
                                    </div>    
                                </form>
                                 {/* mailchimp-alerts Start  */}
                                <div className="mailchimp-alerts text-centre fix pull-right">
                                    <div className="mailchimp-submitting"></div>
                                    <div className="mailchimp-success"></div>
                                    <div className="mailchimp-error"></div>
                                </div>
                                
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* End of Newsletter Area */}
            {/* Footer Widget Area Start */}
            <div className="footer-widget-area">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-3 col-md-6">
                            <div className="single-footer-widget">
                                <div className="footer-logo">
                                    <a href="/"><img src="img/logo/logo.png" alt="" width={100} /></a>
                                </div>
                                <h3>EDUCATION HUB FENI</h3>
                                <div className="social-icons">
                                    <a href="#"><i className="zmdi zmdi-facebook"></i></a>
                                    <a href="#"><i className="zmdi zmdi-rss"></i></a>
                                    <a href="#"><i className="zmdi zmdi-google-plus"></i></a>
                                    <a href="#"><i className="zmdi zmdi-pinterest"></i></a>
                                    <a href="#"><i className="zmdi zmdi-instagram"></i></a>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6">
                            <div className="single-footer-widget">
                                <h3>GET IN TOUCH</h3>
                                <a href="tel:88"><i className="fa fa-phone"></i>88</a>
                                <span><i className="fa fa-envelope"></i>@</span>
                                <span><i className="fa fa-globe"></i>www</span>
                                <span><i className="fa fa-map-marker"></i>FENI SADAR , FENI</span>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6">
                            <div className="single-footer-widget">
                                <h3>Useful Links</h3>
                                <ul className="footer-list">
                                    <li><a href="#">Teachers &amp; Staff</a></li>
                                    <li><a href="#">Our Courses</a></li>
                                    <li><a href="#">Courses Categories</a></li>
                                    <li><a href="#">Support</a></li>
                                    <li><a href="#">Terms &amp; Conditions</a></li>
                                    <li><a href="#">Privacy Policy</a></li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6">
                            <div className="single-footer-widget">
                                <h3>Instagram</h3>
                                <ul id="Instafeed"></ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* End of Footer Widget Area */}
            {/* Footer Area Start */}
            <div className="footer-area">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6 col-md-7 col-12">
                            <span>Copyright &copy; Educat 2022. All right reserved.Created by <a href="#">Edubuzz</a></span>
                        </div>
                        <div className="col-lg-6 col-md-5 col-12">
                            <div className="column-right">
                                <span>Privacy Policy , Terms &amp; Conditions</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* End of Footer Area */}
        </footer>
    )
}

export default Footer
