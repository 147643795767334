import React from 'react';
import { Link } from 'react-router-dom';

function Navbar() {
    return (
        <header>
            <div className="header-top">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-7 col-md-6 col-12">
                            <span>Have any question? +968 547856 254</span>
                        </div>
                        <div className="col-lg-5 col-md-6 col-12">
                            <div className="header-top-right">
                                <span>Phone: +85 4856 5478</span>
                                <span>Email: info@example.com</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="header-logo-menu sticker">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-3 col-12">
                            <div className="logo">
                                <Link to="/"><img src="img/logo/logo.png" alt="EDUCAT" width={100}></img></Link>
                            </div>
                        </div>
                        <div className="col-lg-9 col-12">
                            <div className="mainmenu-area pull-right">
                                <div className="mainmenu d-none d-lg-block">
                                    <nav>
                                        <ul id="nav">
                                            <li className="current"><Link to="/">Home</Link>
                                            </li>
                                            <li><Link to="#">About us</Link>
                                                <ul className="sub-menu">
                                                    <li><Link to="/about">About</Link></li>
                                                    <li><Link to="/director-message">Message From Director</Link></li>
                                                    <li><Link to="/mission">Vision, Mission & Goals</Link></li>
                                                </ul>
                                            </li>
                                            <li><Link to="/gallery">Services</Link>
                                                <ul className="sub-menu">
                                                    <li><Link to="/career-counselling">Free career counselling</Link></li>
                                                    <li><Link to="/file-assessment">Free file assessment</Link></li>
                                                    <li><Link to="/guide-university-selection">Free guide to University selection and programme</Link></li>
                                                    <li><Link to="/interview-preparation">Interview Preparation</Link></li>
                                                    <li><Link to="/visa-guide">Visa Application Guidelines</Link></li>
                                                    <li><Link to="/accommodation-help">Accommodation Help</Link></li>
                                                    <li><Link to="/scholarships">Scholarships</Link></li>
                                                </ul>
                                            </li>
                                            <li><Link to="/study-abroad">Study Abroad</Link></li>
                                            {/* <li><Link to="/">Features</Link>
                                                <ul className="sub-menu">
                                                    <li><Link to="/">Blog<i className="zmdi zmdi-chevron-right"></i></Link>
                                                        <ul className="inside-menu">
                                                            <li><Link to="/blog">Blog</Link></li>
                                                            <li><Link to="/blog-details">Blog Details</Link></li>
                                                            <li><Link to="/blog-left-sidebar">Blog Left Sidebar</Link></li>
                                                            <li><Link to="/blog-no-sidebar">Blog No Sidebar</Link></li>
                                                        </ul>
                                                    </li>
                                                    <li><Link to="/">Shop<i className="zmdi zmdi-chevron-right"></i></Link>
                                                        <ul className="inside-menu">
                                                            <li><Link to="shop.html">Shop</Link></li>
                                                            <li><Link to="single-product.html">Single Product</Link></li>
                                                            <li><Link to="cart.html">Shopping Cart</Link></li>
                                                            <li><Link to="wishlist.html">Wishlist</Link></li>
                                                            <li><Link to="checkout.html">Checkout</Link></li>
                                                            <li><Link to="login-register.html">Login - Register</Link></li>
                                                        </ul>
                                                    </li>
                                                </ul>
                                            </li>
                                            <li><Link to="index.html">Pages</Link>
                                                <ul className="sub-menu">
                                                    <li><Link to="team-details.html">Team Details</Link></li>
                                                    <li><Link to="course.html">Courses Page</Link></li>
                                                    <li><Link to="courses-details.html">Course Details Page</Link></li>
                                                    <li><Link to="event.html">Event Page</Link></li>
                                                    <li><Link to="event-details.html">Event Details Page</Link></li>
                                                    <li><Link to="blog.html">Blog</Link></li>
                                                    <li><Link to="blog-details.html">Blog Details Page</Link></li>
                                                    <li><Link to="blog-left-sidebar.html">Blog Left Sidebar</Link></li>
                                                    <li><Link to="blog-no-sidebar.html">Blog No Sidebar</Link></li>
                                                    <li><Link to="wishlist.html">Wishlist Page</Link></li>
                                                    <li><Link to="checkout.html">Checkout Page</Link></li>
                                                    <li><Link to="cart.html">Shopping Cart Page</Link></li>
                                                    <li><Link to="login-register.html">Login Page</Link></li>
                                                    <li><Link to="contact.html">Contact</Link></li>
                                                    <li><Link to="404.html">404 Error</Link></li>
                                                </ul>
                                            </li>
                                            <li><Link to="shop.html">Shop</Link>
                                                <ul className="sub-menu">
                                                    <li><Link to="single-product.html">Single Product Page</Link></li>
                                                </ul>
                                            </li> */}
                                            <li><Link to="/contact">Contact Us</Link></li>
                                        </ul>
                                    </nav>
                                </div>
                                <ul className="header-search">
                                    <li className="search-menu">
                                        <i id="toggle-search" className="zmdi zmdi-search"></i>
                                    </li>
                                </ul>
                                {/* Search Form */}
                                <div className="search">
                                    <div className="search-form">
                                        <form id="search-form" action="/">
                                            <input type="search" placeholder="Search here..." name="search" />
                                            <button type="submit">
                                                <span><i className="fa fa-search"></i></span>
                                            </button>
                                        </form>                                
                                    </div>
                                </div>
                                {/* End of Search Form */}
                            </div> 
                        </div>
                    </div>
                </div>
            </div>  
            {/* Mobile Menu Area start */}
            <div className="mobile-menu-area">
                <div className="container clearfix">
                    <div className="row">
                        <div className="col-lg-12 col-md-12 col-sm-12">
                            <div className="mobile-menu">
                                <nav id="dropdown">
                                    <ul>
                                        <li><Link to="index.html">HOME</Link>
                                            <ul>
                                                <li><Link to="index.html">Home Version 1</Link></li>
                                                <li><Link to="index-2.html">Home Version 1</Link></li>
                                            </ul>
                                        </li>
                                        <li><Link to="gallery.html">Gallery</Link>
                                            <ul>
                                                <li><Link to="gallery.html">Gallery</Link></li>
                                                <li><Link to="gallery-2.html">Gallery Filtaring</Link></li>
                                                <li><Link to="gallery-four-column.html">Gallery Four Column</Link></li>
                                            </ul>
                                        </li>
                                        <li><Link to="team-details.html">Team Details</Link></li>
                                        <li><Link to="course.html">Courses</Link>
                                            <ul className="sub-menu">
                                                <li><Link to="courses-details.html">Courses Details</Link></li>
                                            </ul>
                                        </li>
                                        <li><Link to="shop.html">Shop</Link>
                                            <ul className="sub-menu">
                                                <li><Link to="single-product.html">Single Product</Link></li>
                                            </ul>
                                        </li>
                                        <li><Link to="event.html">Event</Link>
                                            <ul className="sub-menu">
                                                <li><Link to="event-details.html">Event Details</Link></li>
                                            </ul>
                                        </li>
                                        <li><Link to="blog.html">Blog</Link>
                                            <ul className="sub-menu">
                                                <li><Link to="blog-details.html">Blog Details</Link></li>
                                                <li><Link to="blog-left-sidebar.html">Blog Left Sidebar</Link></li>
                                                <li><Link to="blog-no-sidebar.html">Blog No Sidebar</Link></li>
                                            </ul>
                                        </li>
                                        <li><Link to="contact.html">Pages</Link>
                                            <ul>
                                                <li><Link to="team-details.html">Team Details</Link></li>
                                                <li><Link to="course.html">Courses Page</Link></li>
                                                <li><Link to="courses-details.html">Course Details Page</Link></li>
                                                <li><Link to="event.html">Event Page</Link></li>
                                                <li><Link to="event-details.html">Event Details Page</Link></li>
                                                <li><Link to="blog.html">Blog</Link></li>
                                                <li><Link to="blog-details.html">Blog Details Page</Link></li>
                                                <li><Link to="blog-left-sidebar.html">Blog Left Sidebar</Link></li>
                                                <li><Link to="blog-no-sidebar.html">Blog No Sidebar</Link></li>
                                                <li><Link to="wishlist.html">Wishlist Page</Link></li>
                                                <li><Link to="checkout.html">Checkout Page</Link></li>
                                                <li><Link to="cart.html">Shopping Cart Page</Link></li>
                                                <li><Link to="login-register.html">Login Page</Link></li>
                                                <li><Link to="contact.html">Contact</Link></li>
                                                <li><Link to="404.html">404 Error</Link></li>
                                            </ul>
                                        </li>
                                        <li><Link to="contact.html">Contact us</Link></li>
                                    </ul>
                                </nav>
                            </div>					
                        </div>
                    </div>
                </div>
            </div>
            {/* Mobile Menu Area end   */}
        </header>
    )
}

export default Navbar
