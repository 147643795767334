import React from 'react'

function StudyAbroad() {
  return (
    <div>
        <div class="breadcrumb-banner-area">
            <div class="container">
                <div class="row">
                    <div class="col-md-12">
                        <div class="breadcrumb-text">
                            <h1 class="text-center">UK UNIVERSITIES</h1>
                            <div class="breadcrumb-bar">
                                <ul class="breadcrumb text-center">
                                    <li><a href="/">Home</a></li>
                                    <li>UK UNIVERSITIES</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div className="course-details-area section-padding">
            <div className="container">
                <div className="row">
                    <div className="col-lg-9 col--12">
                        <div className="course-details-right-sidebar">
                            <div className="events-details-img1 img-full">
                                <h3>UK University</h3>
                                <img alt="" src="img/details/1.jpg"></img>
                            </div>
                            <div className="single-item-content">
                                <div className="single-item-comment-view">
                                    <span><i className="zmdi zmdi-accounts"></i>59</span>
                                    <span><i className="zmdi zmdi-favorite"></i>19</span>
                                </div>
                                <div className="single-item-rating">
                                    <i className="zmdi zmdi-star"></i>
                                    <i className="zmdi zmdi-star"></i>
                                    <i className="zmdi zmdi-star"></i>
                                    <i className="zmdi zmdi-star"></i>
                                    <i className="zmdi zmdi-star-half"></i>
                                </div>
                            </div>
                            <div className="course-duration mb-30">
                                <div className="duration-title">
                                    <div className="text"><span>Lessons</span> <span className="text-right">Estimated Time</span></div>
                                </div>
                                <div className="duration-text">
                                    <div className="text"><span>Print design</span> <span className="text-right">15 days</span></div>
                                    <div className="text"><span>web design</span> <span className="text-right">10 days</span></div>
                                    <div className="text"><span>apps design</span> <span className="text-right">16 days</span></div>
                                    <div className="text"><span>web design</span> <span className="text-right">20 days</span></div>
                                    <div className="text"><span>web design</span> <span className="text-right">22 days</span></div>
                                </div>
                            </div>
                            <div className="about-lectures">
                                <h3>Course Description</h3>
                                <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.  t ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.</p>
                            </div>
                            <div className="event-content">
                                <h3 className="content2">Course Content</h3>
                                <ul>
                                    <li>Over 40 lectures and 60 hours of content</li>
                                    <li>Live Project End to End Software Testing Training Included</li>
                                    <li>Information packed practical training starting from basics to advanced testing techniques.</li>
                                    <li>Course content designed by considering current software testing technology and the job market.</li>
                                    <li>Best suitable for beginners to advanced level users and who learn faster when demonstrated.</li>
                                    <li>Practical assignments at the end of every session.</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-3">
                        <div className="sidebar-widget">
                            <div className="single-sidebar-widget">
                                <h3 className="sidebar-title">Courses Information </h3>
                                <ul className="course-menu">
                                    <li>Levels :<span>Beginner</span></li>
                                    <li>Start On :<span>14.09.2018</span></li>
                                    <li>Duration :<span>30 Hours</span></li>
                                    <li>ClassNameclassName Size :<span>25 Persone</span></li>
                                    <li>Lectures :<span>15 Session</span></li>
                                    <li>Time :<span>07am - 12pm</span></li>
                                    <li>Location :<span>07am - 12pm</span></li>
                                    <li>Price :<span>$150.00</span></li>
                                </ul>
                            </div>
                            <div className="single-sidebar-widget">
                                <h4 className="title">Categories</h4>
                                <ul className="course-categoris">
                                    <li><a href="#">Political Science<span>10</span></a></li>
                                    <li><a href="#">Micro Biology<span>15</span></a></li>
                                    <li><a href="#">Computer Science<span>05</span></a></li>
                                    <li><a href="#">Business Leaders Guide<span>19</span></a></li>
                                    <li><a href="#">Become a Product Manage<span>21</span></a></li>
                                    <li><a href="#">Business Economics<span>14</span></a></li>
                                    <li><a href="#">Language Education<span>13</span></a></li>
                                    <li><a href="#">Social Media Management<span>18</span></a></li>
                                </ul>
                            </div>
                            <div className="single-sidebar-widget">
                                <h4 className="title">Recent Course</h4>
                                <div className="recent-content">
                                    <div className="recent-content-item">
                                        <a href="#"><img src="img/event/7.jpg" alt=""></img></a>
                                        <div className="recent-text">
                                            <h4><a href="#">Learn English in</a></h4>
                                            <div className="single-item-comment-view">
                                                <span><i className="zmdi zmdi-eye"></i>59</span>
                                                <span><i className="zmdi zmdi-comments"></i>19</span>
                                            </div>
                                            <p>There are many varf passages of Lorem Ipsuable,amar</p>
                                        </div>
                                    </div>
                                    <div className="recent-content-item">
                                        <a href="#"><img src="img/event/8.jpg" alt=""></img></a>
                                        <div className="recent-text">
                                            <h4><a href="#">Team Works</a></h4>
                                            <div className="single-item-comment-view">
                                                <span><i className="zmdi zmdi-eye"></i>59</span>
                                                <span><i className="zmdi zmdi-comments"></i>19</span>
                                            </div>
                                            <p>There are many varf passages of Lorem Ipsuable,amar</p>
                                        </div>
                                    </div>
                                    <div className="recent-content-item">
                                        <a href="#"><img src="img/event/9.jpg" alt=""></img></a>
                                        <div className="recent-text">
                                            <h4><a href="#">Learn With Fun</a></h4>
                                            <div className="single-item-comment-view">
                                                <span><i className="zmdi zmdi-eye"></i>59</span>
                                                <span><i className="zmdi zmdi-comments"></i>19</span>
                                            </div>
                                            <p>There are many varf passages of Lorem Ipsuable,amar</p>
                                        </div>
                                    </div>
                                    <div className="recent-content-item">
                                        <a href="#"><img src="img/event/10.jpg" alt=""></img></a>
                                        <div className="recent-text">
                                            <h4><a href="#">Writing Skill</a></h4>
                                            <div className="single-item-comment-view">
                                                <span><i className="zmdi zmdi-eye"></i>59</span>
                                                <span><i className="zmdi zmdi-comments"></i>19</span>
                                            </div>
                                            <p>There are many varf passages of Lorem Ipsuable,amar</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}

export default StudyAbroad