import React from 'react';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';

function Home() {
    const options = {
        items: 1,
        nav: true,
        navText:["<div className='nav-btn prev-slide'></div>","<div className='nav-btn next-slide'></div>"],
        rewind: true,
        autoplay: true,
        slideBy: 1,
        dots: true,
        dotsEach: true,
        dotData: true
      };
    return (
        <>
        {/* <div class="loading">
    		<div class="text-center middle">
    			<div class="lds-ellipsis"><div></div><div></div><div></div><div></div></div>
    		</div>
    	</div> */}
       
        <div class="as-mainwrapper">
            <div class="bg-white">
                <div className="slider-area">
                <OwlCarousel className='owl-theme' loop margin={10} nav {...options}>
                    <div class='item'>
                        <img src='../img/slider/1.jpg' />
                    </div>
                    <div class='item'>
                        <img src='../img/slider/2.jpg' />
                    </div>
                </OwlCarousel>
                </div>

                <div className="about-area mt-95">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-7">
                                <div className="about-container">
                                    <h3>Welcome to <span className="orange-color">Education </span> <span className="orange-color"> Hub</span> Feni</h3>
                                    <p>Education Hub Feni was established with motive to provide high quality education service to students who wants to study abroad to earn an excellent learning facilities from UK university and degree is accepted by globally.</p>
                                    <p>Education Hub Feni is registered company under the company act of Bangladesh Government . We are located at Feni Sadar in Bangladesh . We guide students all the way to get admission in the UK University .</p>	      
                                </div>
                            </div>
                            <div className="col-lg-5">
                                <div className="about-image-area img-full">
                                    <img src="img/about/about1.jpg" alt="" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* <div className="course-area section-padding">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="section-title-wrapper">
                                    <div className="section-title">
                                        <h3>POPULAR COURSES</h3>
                                        <p>There are many variations of passages of Lorem Ipsum</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-lg-4 col-md-6 col-12">
                                <div className="single-item">
                                    <div className="single-item-image overlay-effect">
                                        <a href="courses-details.html"><img src="img/course/1.jpg" alt=""></img></a>
                                        <div className="courses-hover-info">
                                            <div className="courses-hover-action">
                                                <div className="courses-hover-thumb">
                                                    <img src="img/teacher/1.png" alt="small images"></img>
                                                </div>
                                                <h4><a href="index.html#">Derek Spafford</a></h4>
                                                <span className="crs-separator">/</span>
                                                <p>Professor</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="single-item-text">
                                        <h4><a href="courses-details.html">Photoshop CC 2017</a></h4>
                                        <p>There are many variations of sages of Lorem Ipsum available, but the mrity have suffered alteration in some orm.</p>
                                        <div className="single-item-content">
                                            <div className="single-item-comment-view">
                                                <span><i className="zmdi zmdi-accounts"></i>59</span>
                                                <span><i className="zmdi zmdi-favorite"></i>19</span>
                                            </div>
                                            <div className="single-item-rating">
                                                <i className="zmdi zmdi-star"></i>
                                                <i className="zmdi zmdi-star"></i>
                                                <i className="zmdi zmdi-star"></i>
                                                <i className="zmdi zmdi-star"></i>
                                                <i className="zmdi zmdi-star-half"></i>
                                            </div>
                                        </div>   
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-6 col-12">
                                <div className="single-item">
                                    <div className="single-item-image overlay-effect">
                                        <a href="courses-details.html"><img src="img/course/2.jpg" alt=""></img></a>
                                        <div className="courses-hover-info">
                                            <div className="courses-hover-action">
                                                <div className="courses-hover-thumb">
                                                    <img src="img/teacher/1.png" alt="small images" ></img>
                                                </div>
                                                <h4><a href="index.html#">Derek Spafford</a></h4>
                                                <span className="crs-separator">/</span>
                                                <p>Professor</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="single-item-text">
                                        <h4><a href="courses-details.html">Illustrator CC 2017</a></h4>
                                        <p>There are many variations of sages of Lorem Ipsum available, but the mrity have suffered alteration in some orm.</p>
                                        <div className="single-item-content">
                                            <div className="single-item-comment-view">
                                                <span><i className="zmdi zmdi-accounts"></i>59</span>
                                                <span><i className="zmdi zmdi-favorite"></i>19</span>
                                            </div>
                                            <div className="single-item-rating">
                                                <i className="zmdi zmdi-star"></i>
                                                <i className="zmdi zmdi-star"></i>
                                                <i className="zmdi zmdi-star"></i>
                                                <i className="zmdi zmdi-star"></i>
                                                <i className="zmdi zmdi-star-half"></i>
                                            </div>
                                        </div>   
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-6 col-12">
                                <div className="single-item">
                                    <div className="single-item-image overlay-effect">
                                        <a href="courses-details.html"><img src="img/course/3.jpg" alt=""></img></a>
                                        <div className="courses-hover-info">
                                            <div className="courses-hover-action">
                                                <div className="courses-hover-thumb">
                                                    <img src="img/teacher/1.png" alt="small images"></img>
                                                </div>
                                                <h4><a href="index.html#">Derek Spafford</a></h4>
                                                <span className="crs-separator">/</span>
                                                <p>Professor</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="single-item-text">
                                        <h4><a href="courses-details.html">Indesign CC 2017</a></h4>
                                        <p>There are many variations of sages of Lorem Ipsum available, but the mrity have suffered alteration in some orm.</p>
                                        <div className="single-item-content">
                                            <div className="single-item-comment-view">
                                                <span><i className="zmdi zmdi-accounts"></i>59</span>
                                                <span><i className="zmdi zmdi-favorite"></i>19</span>
                                            </div>
                                            <div className="single-item-rating">
                                                <i className="zmdi zmdi-star"></i>
                                                <i className="zmdi zmdi-star"></i>
                                                <i className="zmdi zmdi-star"></i>
                                                <i className="zmdi zmdi-star"></i>
                                                <i className="zmdi zmdi-star-half"></i>
                                            </div>
                                        </div>   
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-12 col-sm-12 text-center">
                                <a href="course.html" className="button-default button-large">Browse All Courses <i className="zmdi zmdi-chevron-right"></i></a>
                            </div>
                        </div>
                    </div>
                </div>
                 */}
                {/* <div className="fun-factor-area">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-3 col-md-6 col-12">
                                <div className="single-fun-factor mb-30">
                                    <h2><span className="counter">79</span>+</h2>
                                    <h4>Teachers</h4>
                                </div>
                            </div>
                            <div className="col-lg-3 col-md-6 col-12">
                                <div className="single-fun-factor mb-30">
                                    <h2><span className="counter">120</span>+</h2>
                                    <h4>Members</h4>
                                </div>
                            </div>
                            <div className="col-lg-3 col-md-6 col-12">
                                <div className="single-fun-factor mb-30">
                                    <h2><span className="counter">36</span>+</h2>
                                    <h4>Courses</h4>
                                </div>
                            </div>
                            <div className="col-lg-3 col-md-6 col-12">
                                <div className="single-fun-factor mb-30">
                                    <h2><span className="counter">20</span>+</h2>
                                    <h4>Countries</h4>
                                </div>
                            </div>
                        </div>
                    </div>
                </div> */}
                
                {/* <div className="latest-area section-padding bg-white">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="section-title-wrapper">
                                    <div className="section-title">
                                        <h3>Latest News</h3>
                                        <p>There are many variations of passages</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-lg-4 col-md-6 col-12">
                                <div className="single-latest-item">
                                    <div className="single-latest-image">
                                        <a href="blog-details.html">
                                            <img src="img/latest/1.jpg" alt=""></img></a>
                                    </div>
                                    <div className="single-latest-text">
                                        <h3><a href="blog-details.html">Learn English in ease</a></h3>
                                        <div className="single-item-comment-view">
                                            <span><i className="zmdi zmdi-calendar-check"></i>25 jun 2050</span>
                                            <span><i className="zmdi zmdi-eye"></i>59</span>
                                            <span><i className="zmdi zmdi-comments"></i>19</span>
                                        </div>
                                        <p>There are many variaons of passages of Lorem Ipsuable, amrn in some by injected humour, </p>
                                        <a href="blog-details.html" className="button-default">Read More</a>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-6 col-12">
                                <div className="single-latest-item">
                                    <div className="single-latest-image">
                                        <a href="blog-details.html">
                                            <img src="img/latest/2.jpg" alt="" ></img></a>
                                    </div>
                                    <div className="single-latest-text">
                                        <h3><a href="blog-details.html">Learn English in ease</a></h3>
                                        <div className="single-item-comment-view">
                                            <span><i className="zmdi zmdi-calendar-check"></i>25 jun 2050</span>
                                            <span><i className="zmdi zmdi-eye"></i>59</span>
                                            <span><i className="zmdi zmdi-comments"></i>19</span>
                                        </div>
                                        <p>There are many variaons of passages of Lorem Ipsuable, amrn in some by injected humour, </p>
                                        <a href="blog-details.html" className="button-default">Read More</a>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-6 col-12">
                                <div className="single-latest-item">
                                    <div className="single-latest-image">
                                        <a href="blog-details.html"><img src="img/latest/4.jpg" alt="" ></img></a>
                                    </div>
                                    <div className="single-latest-text">
                                        <h3><a href="blog-details.html">Learn English in ease</a></h3>
                                        <div className="single-item-comment-view">
                                            <span><i className="zmdi zmdi-calendar-check"></i>25 jun 2050</span>
                                            <span><i className="zmdi zmdi-eye"></i>59</span>
                                            <span><i className="zmdi zmdi-comments"></i>19</span>
                                        </div>
                                        <p>There are many variaons of passages of Lorem Ipsuable, amrn in some by injected humour, </p>
                                        <a href="blog-details.html" className="button-default">Read More</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div> */}
                
                {/* <div className="product-area section-bottom-padding bg-white">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="section-title-wrapper">
                                    <div className="section-title">
                                        <h3>Online Library</h3>
                                        <p>There are many variations of passages</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-lg-3 col-md-6">
                                <div className="single-product mb-30">
                                    <div className="product-img img-full">
                                        <a href="single-product.html" tabIndex="0">
                                            <img src="img/product/16.jpg" alt="" ></img>
                                        </a>
                                        <span className="onsale">Sale!</span>
                                    </div>
                                    <div className="product-content">
                                        <h2><a href="single-product.html" tabIndex="0">Clock</a></h2>
                                        <div className="product-price">
                                            <div className="price-box">
                                                <span className="regular-price">$115.00</span>
                                            </div>
                                            <div className="add-to-cart">
                                                <a href="index.html#" tabIndex="0">Add To Cart</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-3 col-md-6">
                                <div className="single-product mb-30">
                                    <div className="product-img img-full">
                                        <a href="single-product.html" tabIndex="0">
                                            <img src="img/product/2.jpg" alt="" ></img>
                                        </a>
                                        <span className="onsale">Sale!</span>
                                    </div>
                                    <div className="product-content">
                                        <h2><a href="single-product.html" tabIndex="0">Eleifend quam</a></h2>
                                        <div className="product-price">
                                            <div className="price-box">
                                                <span className="regular-price">$115.00</span>
                                            </div>
                                            <div className="add-to-cart">
                                                <a href="index.html#" tabIndex="0">Add To Cart</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-3 col-md-6">
                                <div className="single-product mb-30">
                                    <div className="product-img img-full">
                                        <a href="single-product.html" tabIndex="0">
                                            <img src="img/product/4.jpg" alt="" ></img>
                                        </a>
                                        <span className="onsale">Sale!</span>
                                    </div>
                                    <div className="product-content">
                                        <h2><a href="single-product.html" tabIndex="0">Pen quam</a></h2>
                                        <div className="product-price">
                                            <div className="price-box">
                                                <span className="regular-price">$115.00</span>
                                            </div>
                                            <div className="add-to-cart">
                                                <a href="index.html#" tabIndex="0">Add To Cart</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-3 col-md-6">
                                <div className="single-product mb-30">
                                    <div className="product-img img-full">
                                        <a href="single-product.html" tabIndex="0">
                                            <img src="img/product/3.jpg" alt="" ></img>
                                        </a>
                                        <span className="onsale">Sale!</span>
                                    </div>
                                    <div className="product-content">
                                        <h2><a href="single-product.html" tabIndex="0">Note Khata</a></h2>
                                        <div className="product-price">
                                            <div className="price-box">
                                                <span className="regular-price">$115.00</span>
                                            </div>
                                            <div className="add-to-cart">
                                                <a href="index.html#" tabIndex="0">Add To Cart</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div> */}
                
                {/* <div className="testimonial-area">
                    <div className="container">
                        <div className="row">
                            <div className="testimonial-slider owl-carousel">
                                <div className="col-12">
                                    <div className="single-testimonial-area">
                                        <div className="testimonial-image">
                                            <img src="img/testimonial/testimonial1.png" alt="" ></img>
                                        </div>
                                        <div className="testimonial-content">
                                            <p className="author-desc">  All Perfect !! I have three sites with magento , this theme is the best !! Excellent support , advice theme installation package , sorry for English, are Italian but I had no problem !! Thank you ! ..</p>
                                            <p className="testimonial-author">Alva Ono</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="single-testimonial-area">
                                        <div className="testimonial-image">
                                            <img src="img/testimonial/testimonial2.png" alt="" ></img>
                                        </div>
                                        <div className="testimonial-content">
                                            <p className="author-desc">Perfect Themes and the best of all that you have many options to choose! Best Support team ever!Very fast responding and experts on their fields! Thank you very much! ..</p>
                                            <p className="testimonial-author">Amber Laha</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="single-testimonial-area">
                                        <div className="testimonial-image">
                                            <img src="img/testimonial/testimonial3.png" alt="" />
                                        </div>
                                        <div className="testimonial-content">
                                            <p className="author-desc"> Code, template and others are very good. The support has served me immediately and solved my problems when I need help. Are to be congratulated. Att Renan Andrade ..</p>
                                            <p className="testimonial-author">Dewey Tetzlaff</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="single-testimonial-area">
                                        <div className="testimonial-image">
                                            <img src="img/testimonial/testimonial4.png" alt="" />
                                        </div>
                                        <div className="testimonial-content">
                                            <p className="author-desc">  All Perfect !! I have three sites with magento , this theme is the best !! Excellent support , advice theme installation package , sorry for English, are Italian but I had no problem !! Thank you ! ..</p>
                                            <p className="testimonial-author">Lavina Wilderman</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="single-testimonial-area">
                                        <div className="testimonial-image">
                                            <img src="img/testimonial/testimonial5.png" alt="" ></img>
                                        </div>
                                        <div className="testimonial-content">
                                            <p className="author-desc">  All Perfect !! I have three sites with magento , this theme is the best !! Excellent support , advice theme installation package , sorry for English, are Italian but I had no problem !! Thank you ! ..</p>
                                            <p className="testimonial-author">Stefano</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div> */}

                {/* <div className="event-area section-padding bg-white">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="section-title-wrapper">
                                    <div className="section-title">
                                        <h3>OUR EVENTS</h3>
                                        <p>There are many variations of passages</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-lg-4 col-md-6">
                                <div className="single-event-item">
                                    <div className="single-event-image">
                                        <a href="event-details.html">
                                            <img src="img/event/1.jpg" alt="" ></img>
                                            <span>15 Jun</span>
                                        </a>
                                    </div>
                                    <div className="single-event-text">
                                        <h3><a href="event-details.html">Learn English in ease</a></h3>
                                        <div className="single-item-comment-view">
                                            <span><i className="zmdi zmdi-time"></i>4.00 pm - 8.00 pm</span>
                                            <span><i className="zmdi zmdi-pin"></i>Dhaka Bangladesh</span>
                                        </div>
                                        <p>There are many variaons of passa of Lorem Ipsuable, amrn in sofby injected humour, amr sarata din megla....</p>
                                        <a className="button-default" href="event-details.html">LEARN Now</a>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-6">
                                <div className="single-event-item">
                                    <div className="single-event-image">
                                        <a href="event-details.html">
                                            <img src="img/event/2.jpg" alt="" ></img>
                                            <span>20 Apr</span>
                                        </a>
                                    </div>
                                    <div className="single-event-text">
                                        <h3><a href="event-details.html">Learn English in ease</a></h3>
                                        <div className="single-item-comment-view">
                                            <span><i className="zmdi zmdi-time"></i>4.00 pm - 8.00 pm</span>
                                            <span><i className="zmdi zmdi-pin"></i>Jessore Bangladesh</span>
                                        </div>
                                        <p>There are many variaons of passa of Lorem Ipsuable, amrn in sofby injected humour, amr sarata din megla....</p>
                                        <a className="button-default" href="event-details.html">LEARN Now</a>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-6">
                                <div className="single-event-item">
                                    <div className="single-event-image">
                                        <a href="event-details.html">
                                            <img src="img/event/3.jpg" alt="" ></img>
                                            <span>06 Dec</span>
                                        </a>
                                    </div>
                                    <div className="single-event-text">
                                        <h3><a href="event-details.html">Learn English in ease</a></h3>
                                        <div className="single-item-comment-view">
                                            <span><i className="zmdi zmdi-time"></i>4.00 pm - 8.00 pm</span>
                                            <span><i className="zmdi zmdi-pin"></i>Dhaka. Bangladesh</span>
                                        </div>
                                        <p>There are many variaons of passa of Lorem Ipsuable, amrn in sofby injected humour, amr sarata din megla....</p>
                                        <a className="button-default" href="event-details.html">LEARN Now</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>     */}


            </div>   
        {/* End of Bg White */}
        </div>    
        {/* End of Main Wrapper Area */}
        </>
    )
}

export default Home
