import React from 'react'

function Slider() {
    return (
        <div className="slider-area">
                <div className="hero-slider owl-carousel">
                    <div className="single-slider" style={{backgroundImage: 'url("img/slider/1.jpg")'}}> 
                        <div className="hero-slider-content">
                            <h1>Education Needs <br /> Complete Solution</h1>
                            <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Atque unde, at molestias voluptatem praesentium quia magnam? Iste aliquam, voluptas sapiente animi, repudiandae officiis voluptatem tempore alias nihil. Aperiam voluptatum, velit.</p>
                            <div className="slider-btn">
                                <a className="button-default" href="course.html">View Courses</a>
                            </div>
                        </div>
                    </div>
                    
                    <div className="single-slider" style={{backgroundImage: 'url("img/slider/2.jpg")'}}>   
                        <div className="hero-slider-content">
                            <h1>Education Needs <br /> Complete Solution</h1>
                            <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Atque unde, at molestias voluptatem praesentium quia magnam? Iste aliquam, voluptas sapiente animi, repudiandae officiis voluptatem tempore alias nihil. Aperiam voluptatum, velit.</p>
                            <div className="slider-btn">
                                <a className="button-default" href="course.html">View Courses</a>
                            </div>
                        </div>
                    </div>
                    {/* <!--Single Slider End--> */}
                </div>
            </div>
    )
}

export default Slider
