import React from 'react'

function Mission() {
    return (
        <div className="about-area mt-95">
            <div className="container">
                <div className="row">
                    <div className="col-lg-7">
                        <div className="about-container">
                            <h3>Our <span className="orange-color">Mission </span> </h3>
                            <p>Our mission is to  provides consultancy support, customized solution to the students aspiring to study overseas  and also credible relationship with the partners.</p>

                            <h3>Our <span className="orange-color">Vision  </span> </h3>
                            <p>Our vision is to provide one stop study abroad solution for the students and help them to achieve their goal through our dedicated counselling and guidance .</p>

                            <h3>Our <span className="orange-color">Goal </span> </h3>
                            <p>our aim is to achieve the mission and vision thorugh the objectives including adequate knowledge about the study destination , institution and programme provided by the UK University and also develop necessary skills and knowledge in education counselling to the international students .</p>
                        </div>
                    </div>
                    <div className="col-lg-5">
                        <div className="about-image-area img-full">
                            <img src="img/about/about1.jpg" alt="" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Mission
