import Navbar from "./Components/Navbar";
import Footer from "./Components/Footer";
import Home from "./Components/Home";
import About from "./Components/About";
import Contact from "./Components/Contact";
import Mission from "./Components/Mission";
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Slider from "./Components/Slider";
import DirectorMessage from "./Components/DirectorMessage";
import StudyAbroad from "./Components/StudyAbroad";

function App() {
  return (
    <BrowserRouter>
      <Navbar/>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/slider" element={<Slider />} />
          
          <Route path="/about" element={<About/>} />
          <Route path="/mission" element={<Mission/>} />
          <Route path="/director-message" element={<DirectorMessage/>} />
          <Route path="/contact" element={<Contact/>} />
          <Route path="/study-abroad" element={<StudyAbroad/>} />
        </Routes>
      <Footer/>
    </BrowserRouter>
  );
}

export default App;
